import React, { Component } from 'react';

import window from '../components/window';

export default class Gyro extends Component {
  values = [];
  timer= null;

  state = {
    frequencyInHz: 1,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.frequencyInHz !== state.frequencyInHz) {
      if (Gyro.timer) {
        clearTimeout(Gyro.timer);
        Gyro.timer = null;
      }
      return {
        frequencyInHz: props.frequencyInHz,
      };
    }
    return null;
  }

  componentDidMount() {
    window.addEventListener('deviceorientation', this.handleOrientation);
  }

  handleOrientation = (event) => {
    const { alpha, beta, gamma }  = event;
    this.values.push({ alpha, beta, gamma });
  }

  sendUpdate = () => {
    const n = this.values.length;
    if (n > 0) {
      const accumulated = this.values.reduce(
        (acc, cur) =>  ({ 
          alpha: acc.alpha + (cur.alpha / n),
          beta: acc.beta + (cur.beta / n),
          gamma: acc.gamma + (cur.gamma / n),
        }), 
        { alpha: 0, beta: 0, gamma: 0 }
      );
      this.values = [];
      this.props.onUpdate({ 
        alpha: Math.round(accumulated.alpha),
        beta: Math.round(accumulated.beta),
        gamma: Math.round(accumulated.gamma),
      });
    }
  }  

  render() {
    if (this.state.frequencyInHz && !Gyro.timer) {
      Gyro.timer = setInterval(this.sendUpdate.bind(this), 1000 / this.state.frequencyInHz)
    }
    return (
      <div>
        { this.props.children }
      </div>
    );
  }
}