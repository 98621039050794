import React, { Component } from 'react';

import Gyro from '../helpers/Gyro';
import window from '../components/window';
import { hookupGridBoard } from '../Config';

export default class Companion extends Component {
  state = {
    alpha: 0,
    beta: 0,
    gamma: 0,
    companionId: window.location.pathname.split('/').pop()
  }

  valuesReceived = values => {
    this.setState(values);
    if (hookupGridBoard) {
      const hookupUrl = `${hookupGridBoard}/ingress?gamma=${this.state.gamma}&beta=${this.state.beta}`
      fetch(hookupUrl)
    }
  }

  renderIndicator = () => {
    return (
      <div
        style={{
          position: 'absolute',
          width: '90%',
          height: 50,
          left: '5%',
          top: 100,
          border: 'solid 1px black',
          borderRadius: 3
        }}
      >
        <div
          style={{
            position: 'relative',
            left: `${50 + Math.floor(this.state.gamma / 90 * 50)}%`, // gamma is in range [0: 90]
            backgroundColor: 'red',
            width: 2,
            height: '100%'
          }}
        >
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Gyro 
          frequencyInHz={5}
          onUpdate={this.valuesReceived}
        /> 
        { this.renderIndicator() }
      </div>
    );
  }
}
